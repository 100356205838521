import slugify from 'slugify'

import { CollectionCategoryEnum } from '~/app/generated/graphql'
import { Button } from '~/components/Button/Button'
import { gt } from '~/lib/gt'

import LocationMenu from '../LocationMenu/LocationMenuView'
import { ReservationOfferCard } from '../ReservationOfferCard'
import cardStyles from '../ReservationOfferCard/ReservationOfferCard.module.css'

import styles from './CollectionSection.module.css'

export type Restaurant = {
  __typename?: 'Restaurant'
  id: string
  name: string
  description?: string | null
  slug: string
  favorited: boolean
  imageFiles: Array<{
    __typename?: 'ImageFile'
    id: string
    preview?: string | null
  }>
  festivalEditionRestaurants?: Array<{
    __typename?: 'FestivalEditionRestaurant'
    id: string
    festivalEdition: {
      __typename?: 'FestivalEdition'
      id: string
      code: string
    }
  }> | null
}

export type ReservableExtra = {
  __typename?: 'ReservableExtra'
  id: string
  name: string
  slug: string
  price: number
  description: string
  type: string
  restaurant: { __typename?: 'Restaurant'; name: string }
  image?: { __typename?: 'ImageFile'; preview?: string | null } | null
}

export type CollectionItem = Restaurant | ReservableExtra

export type Node = {
  __typename?: 'PolymorphicCollection'
  id: string
  category: CollectionCategoryEnum
  position: number
  title?: string | null
  restaurants?: Array<Restaurant> | null
  reservableExtras?: Array<ReservableExtra> | null
  elements?: Array<{ __typename?: 'CollectionElement'; elementId: string; elementType: string }> | null
  section: { __typename?: 'Section'; slug: string }
}

type Props = {
  collection: Node
  title?: string
  buttonText: string
  theme?: typeof styles
  cardTheme?: typeof cardStyles
  customHref?: string
  listingRedirect?: string
  showLocationMenu?: boolean
  feCode?: string
  selectLabel?: string
}

export const CollectionSection = ({
  collection,
  title,
  customHref,
  buttonText,
  listingRedirect,
  theme = styles,
  cardTheme = cardStyles,
  showLocationMenu,
  feCode,
  selectLabel,
}: Props) => {
  const mergedTheme = { ...styles, ...theme }
  const category = collection.category
  const items = category === CollectionCategoryEnum.Restaurant ? collection.restaurants : collection.reservableExtras
  const buttonHref = customHref || `/collection/${collection.id}-${slugify((collection.title || '').toLocaleLowerCase())}`
  const { tp } = gt

  return (
    <div className={`${category === CollectionCategoryEnum.Reservableextra ? mergedTheme.background : ''}`}>
      <section className={`container section ${mergedTheme.section}`}>
        <div className={mergedTheme.topBar}>
          <h1 className={`heading1 ${mergedTheme.title}`}>{title ?? collection?.title}</h1>
          <div className={mergedTheme.right}>
            {showLocationMenu && <LocationMenu selectLabel={selectLabel} />}
            <Button href={buttonHref} className={`${mergedTheme.button} ${mergedTheme.buttonDesktop}`}>
              {buttonText}
            </Button>
          </div>
        </div>
        <div className={mergedTheme.items}>
          {items?.map(item => (
            <ReservationOfferCard
              key={item.id}
              item={item as CollectionItem}
              type={category === CollectionCategoryEnum.Reservableextra ? 'regular' : 'wide'}
              category={category}
              theme={cardTheme}
              listingRedirect={listingRedirect}
              guestLabel={tp('OfferCard', 'Guest')}
              feCode={feCode}
            />
          ))}
        </div>
        <Button href={buttonHref} size='small' className={`${mergedTheme.button} ${mergedTheme.buttonMobile}`}>
          {buttonText}
        </Button>
      </section>
    </div>
  )
}
