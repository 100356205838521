'use client'

import get from 'lodash.get'

import { useSuspenseQuery } from '@apollo/client'

import { DEFAULT_REGION, DEFAULT_SECTION_SLUG } from '~/utils/consts'
import { notEmpty } from '~/utils/function'

import { HomepageQueryDocument, HomepageQueryQuery, HomepageQueryQueryVariables } from '~/app/generated/graphql'

import { Button } from '../Button/Button'
import { CollectionSection } from '../CollectionSection'
import { useLocation } from '../LocationMenu/LocationMenuUtils'
import LocationMenu from '../LocationMenu/LocationMenuView'

import styles from './LocationCollectionSection.module.css'

interface LocationCollectionSectionProps {
  redirectUrl: string
  festivalSlug: string
  buttonText: string /* texts are from props cuz client-side translations don't work, */
  title: string /* so we getting them from server-side page */
  collectionTheme: typeof styles
  feCode?: string
  selectLabel?: string
}

export const LocationCollectionSection = ({
  redirectUrl,
  festivalSlug,
  buttonText,
  title,
  collectionTheme,
  feCode,
  selectLabel,
}: LocationCollectionSectionProps) => {
  const { selectedRegion } = useLocation()
  const { data: collectionData } = useSuspenseQuery<HomepageQueryQuery, HomepageQueryQueryVariables>(HomepageQueryDocument, {
    variables: {
      region_id: selectedRegion?.id || DEFAULT_REGION.id,
      section_slug: DEFAULT_SECTION_SLUG,
    },
  })

  const collectionsEdges = get(collectionData, 'collections.edges', [])
  const collections = collectionsEdges.map(edge => edge?.node).filter(notEmpty)
  return collections && collections.length > 0 ? (
    collections.map(collection => (
      <CollectionSection
        key={collection.id}
        title={title}
        collection={collection}
        buttonText={buttonText}
        theme={collectionTheme}
        customHref={redirectUrl}
        listingRedirect={festivalSlug}
        feCode={feCode}
        selectLabel={selectLabel}
        showLocationMenu
      />
    ))
  ) : (
    <section className={`container section ${styles.wrapper}`}>
      <h1 className={`heading1 ${styles.title}`}>{title}</h1>
      <div className={styles.left}>
        <LocationMenu selectLabel={selectLabel} />
        <Button href={redirectUrl} className={`${styles.button}`}>
          {buttonText}
        </Button>
      </div>
    </section>
  )
}
